<template>
  <div class="allPage">
    <heads :more="showMore" :msg="title_msg"></heads>
    <van-list
      v-show="isShow"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(thread,index) in list" :key="index" @click="go_url(thread)">
        <datalists
          :type="1"
          :listType="2"
          :thumb="thread.pic"
          :atitle="thread.title"
          :createTime="thread.create_time"
          :vNum="thread.view_num"
          :cNum="thread.reply_num"
        ></datalists>
      </div>
    </van-list>
    <div v-show="!isShow" class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt />
      <p>您还没有收藏的资料~</p>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import { dataCollection } from '@/api/my_user'
import datalists from '@/compontens/datalist'
import tool from '@/utils/tools.js'
export default {
  components: {
    heads,
    commontop,
    datalists
  },
  data () {
    return {
      title_msg: '资料收藏',
      isshow: false,
      limit: 10,
      page: 1,
      total: 0,
      retlen: 0,
      list: [],
      loading: false,
      finished: false,
      isShow: true,
      showMore: true
    }
  },
  mounted () {
    // this.get_list()
  },
  beforeMount () {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function (e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  methods: {
    destroyed () {
      this.list = []
      this.page = 1
      this.total = 0
    },
    go_url (item) {
      const url = item.url_bbs
      window.location.href = url
    },
    onLoad () {
      var params = {
        page: this.page,
        limit: this.limit
      }
      dataCollection(params).then(res => {
        if (!res.result.list.length && !this.list.length) {
          // if (true) {
          this.isShow = false
          return false
        }
        console.log(res, 123456)
        this.list = this.list.concat(res.result.list)
        this.total = res.result.count // 数据总条数
        this.retlen = res.result.list.length // 本次返回数据的长度
        console.log(123321)
        if (this.retlen < this.limit) {
          console.log(321)
          this.finished = true // 数据加载完毕显示 没有更多了
          this.isShow = true
        }
        this.page++
        this.loading = false
        console.log(this.list)
      })
    },
    clicks () {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
// .img_h {
//   width: 40px;
//   height: 40px;
//   position: absolute;
//   right: 30px;
//   top: 44px;
//   z-index: 1;
// }
// .eye_img {
//   width: 28px;
//   height: 18px;
//   position: absolute;
//   bottom: 44px;
//   left: 30px;
// }
// .kuang_img {
//   width: 24px;
//   height: 18px;
//   position: absolute;
//   bottom: 44px;
//   left: 190px;
// }

// .left_title {
//   font-size: 30px;
//   word-wrap: break-word;
//   word-break: normal;
//   width: 353px;
//   letter-spacing: 1px;
//   padding: 37px 0 0 32px;
//   line-height: 56px;
// }
// .people_bro {
//   padding-left: 66px;
//   float: left;
//   color: #9b9b9b;
// }
// .people_gen {
//   float: left;
//   padding-left: 69px;
//   color: #9b9b9b;
// }
// .bro_time {
//   float: right;
//   padding: 4px 33px 0 0;
//   color: #9b9b9b;
// }
// .main {
//   width: 100%;
//   height: 276px;
//   border-bottom: #efefef 1px solid;
//   overflow: hidden;
//   position: relative;
// }
// .ll_img {
//   width: 279px;
//   height: 157px;
//   margin-top: 32px;
// }
// .main_top_left {
//   float: left;
//   width: 440px;
//   height: 205px;
// }
// .main_top_right {
//   float: left;
//   overflow: hidden;
//   height: 205px;
// }
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
